import { colors, type Skin } from '@orus.eu/pharaoh'
import { fontweight } from '@orus.eu/pharaoh/src/foundation/font-weight-tokens'
import { defaultSkin, poppinsFontFamily } from '@orus.eu/pharaoh/src/skin/default-skin'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import HorizontalLogoSvg from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.png'

export const superindepSkin: Skin = {
  avatarUrl,
  quoteHelpBannerImageUrl,
  horizontalLogo: {
    aspectRatio: 274 / 48,
    ImageComponent: HorizontalLogoSvg,
  },
  showByOrusInHeader: 'by',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colors.white,
    backgroundColor: colors.sky.dark,
  },
  helpBlockTextColor: '#E7E4F4',
  subscriptionCalloutColors: {
    backgroundColor: '#1F0F69',
    accentColor: '#E7E4F4',
    textColor: '#E7E4F4',
  },
  stripe: defaultSkin.stripe,
  additionalStylesheets: [],
  cssVariables: {
    '--skin-font': poppinsFontFamily,
    '--skin-font-title': poppinsFontFamily,
    '--skin-font-button': poppinsFontFamily,
    '--skin-font-subtitle1': poppinsFontFamily,
    '--skin-font-weight-title': fontweight.medium,
    '--skin-font-weight-subtitle1': fontweight.medium,
    '--skin-font-weight-subtitle2': fontweight.medium,
    '--skin-font-weight-body1': fontweight.regular,
    '--skin-font-weight-body1-medium': fontweight.medium,
    '--skin-font-weight-body1-semibold': fontweight.semibold,
    '--skin-font-weight-body2': fontweight.regular,
    '--skin-font-weight-body2-medium': fontweight.medium,
    '--skin-font-weight-body2-semibold': fontweight.semibold,
    '--skin-font-weight-caption': fontweight.regular,
    '--skin-font-weight-caption-medium': fontweight.medium,
    '--skin-font-weight-button': fontweight.medium,
    '--skin-font-weight-input': fontweight.regular,
    '--skin-blue-100': '#F5F4FA',
    '--skin-blue-200': '#E7E4F4',
    '--skin-blue-300': '#C1B7EB',
    '--skin-blue-400': '#735DD7',
    '--skin-blue-500': '#1F0F69',
    '--skin-blue-600': '#1E0F58',
    '--skin-blue-700': '#190A52',
    '--skin-blue-800': '#12073E',
    '--skin-blue-900': '#0C0331',
    '--skin-gray-100': '#FBFBFB',
    '--skin-gray-200': '#E1E1E1',
    '--skin-gray-300': '#E0E0E0',
    '--skin-gray-400': '#CDCDCD',
    '--skin-gray-500': '#AEAEAE',
    '--skin-gray-600': '#939393',
    '--skin-gray-700': '#6F6F6F',
    '--skin-gray-800': '#3F3F3F',
    '--skin-gray-900': '#181818',
    '--skin-green-100': '#E5FFF2',
    '--skin-green-200': '#A3FFD0',
    '--skin-green-300': '#47FFA2',
    '--skin-green-400': '#01FF7F',
    '--skin-green-500': '#00CC65',
    '--skin-green-600': '#009A4C',
    '--skin-green-700': '#006834',
    '--skin-green-800': '#004723',
    '--skin-green-900': '#002713',
    '--skin-yellow-100': '#FFFDEE',
    '--skin-yellow-200': '#FFF8CB',
    '--skin-yellow-300': '#FFF195',
    '--skin-yellow-400': '#FFEB62',
    '--skin-yellow-500': '#FFE428',
    '--skin-yellow-600': '#D9BD00',
    '--skin-yellow-700': '#9E8A00',
    '--skin-yellow-800': '#433A00',
    '--skin-yellow-900': '#1D1902',
    '--skin-orange-100': '#FFF8EF',
    '--skin-orange-200': '#FFE9CF',
    '--skin-orange-300': '#FED7AA',
    '--skin-orange-400': '#FEB765',
    '--skin-orange-500': '#FE9925',
    '--skin-orange-600': '#FA8803',
    '--skin-orange-700': '#B76202',
    '--skin-orange-800': '#794202',
    '--skin-orange-900': '#271704',
    '--skin-red-100': '#FFECF1',
    '--skin-red-200': '#FFBACC',
    '--skin-red-300': '#FF9CB7',
    '--skin-red-400': '#FF779B',
    '--skin-red-500': '#FF5884',
    '--skin-red-600': '#F13466',
    '--skin-red-700': '#DC1A4D',
    '--skin-red-800': '#8F062A',
    '--skin-red-900': '#5E021A',
    '--skin-white': '#FFFFFF',
    '--skin-black': '#000000',
    '--skin-linen': '#FAF9F7',
    '--skin-sky-dark': '#1F0F69',
    '--skin-sky-light': '#1F0F69',
    // we use a gradient, even if its flat for this theme, because the variable is used as a background image
    '--skin-sky-gradient': 'radial-gradient(400% 100% at 0% 100%, #1F0F69 0%, #1F0F69 100%)',
    '--skin-periwinkle-dark': '#DED1F9',
    '--skin-periwinkle-light': '#F1EBFC',
    '--skin-periwinkle-gradient': 'radial-gradient(400% 100% at 0% 100%, #DED1F9 0%, #E1D8F8 100%)',
    '--skin-mindaro-dark': '#E1F5A8',
    '--skin-mindaro-light': '#EFF9CE',
    '--skin-mindaro-gradient': 'radial-gradient(400% 100% at 0% 100%, #E1F5A8 0%, #E4F5B5 100%)',
    '--skin-jasmine-dark': '#FFECA8',
    '--skin-jasmine-light': '#FFF8DE',
    '--skin-jasmine-gradient': 'radial-gradient(400% 100% at 0% 100%, #FFECA8 0%, #FFF1BE 100%)',
    '--skin-peach-dark': '#FFC4A2',
    '--skin-peach-light': '#FFE6D5',
    '--skin-peach-gradient': 'radial-gradient(400% 100% at 0% 100%, #FFC4A2 0%, #FFD0B4 100%)',
  },
}
